<template>
  <footer :class="['Footer', { 'Footer--restaurant': isRestaurantPage }]" role="contentinfo" v-if="links?.length">
    <p aria-level="2" class="accessibility-sr-only" role="heading">{{ $t('ux.molecules.footer.usefulLinks') }}</p>
    <section class="Footer__legal Grid-layout">
      <nav :aria-label="$t('ux.molecules.footer.legalMentions')" class="Footer__legal-nav">
        <ul class="Footer__nav-elements">
          <ux-atoms-typo :key="link.url" as="li" class="Footer__nav-element" v-for="link in links" variant="text-xsmall">
            <ux-atoms-link :classic="false" :href="link.url" :target="link.target" class="Footer__nav-link" v-if="link.url">
              {{ link.label }}
            </ux-atoms-link>
          </ux-atoms-typo>
          <ux-atoms-typo as="li" class="Footer__nav-element" variant="text-xsmall">
            <ux-atoms-link :classic="false" :href="sitemapLink" class="Footer__nav-link">
              {{ $t('ux.molecules.footer.sitemap') }}
            </ux-atoms-link>
          </ux-atoms-typo>
        </ul>
      </nav>
      <ux-atoms-typo as="p" class="Footer__copyright" variant="text-xsmall">{{ $t('ux.molecules.footer.copyright') }}</ux-atoms-typo>
    </section>
  </footer>
</template>
<script lang="ts" setup>
import { useFooterAsyncData } from '~/graphql';
import { getPageName } from '~/helpers/getPageName';

import { LinkProps } from '../../atoms/Link/Link.vue';

interface Props {
  isWebView?: boolean;
}

defineProps<Props>();

// TODO: don't forget to hide links except legals when we are in webview

const { data } = await useFooterAsyncData();
const route = useRoute();

const localePath = useLocalePath();

const isRestaurantPage = computed(() => {
  return getPageName(route.name)?.startsWith('restaurantCode');
});
const links = computed(() =>
  data.value?.footer?.routes?.map((route) => ({
    label: route?.label,
    target: route?.externalUrl ? '_blank' : ('_self' as LinkProps['target']),
    url: route?.externalUrl ?? (route?.internalPageSlug ? localePath(`/page/${route?.internalPageSlug}`) : '')
  }))
);

const sitemapLink = computed(() => {
  return localePath({
    name: 'sitemap'
  });
});
</script>
<style lang="scss" scoped>
@use 'Footer.scss';
</style>
