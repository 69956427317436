<template>
  <div class="Template">
    <ux-atoms-link href="#main" skip>{{ $t('layout.skipToMain') }}</ux-atoms-link>
    <ux-molecules-main-nav v-if="!isWebView" />
    <main class="app-main" id="main" role="main">
      <slot />
    </main>
    <ux-molecules-footer :is-web-view="isWebView" />
  </div>
</template>

<script lang="ts" setup>
const { isWebView } = useWebView();
const userStore = useUserStore();

onMounted(async () => {
  if (!userStore.hasLoadedUser) {
    await userStore.loadUser();
  }
});
</script>
